header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw;
  position: fixed;
  z-index: 200;
  width: 100%;
}

header .logo {
  width: 125px;
}

header .nav {
  padding: 0 0.3rem;
}

.logo {
  text-align: center;
  text-decoration: none;
  color: black;
  font-size: 24px;
  font-family: "benjamine-logo";
}

.menu {
  display: flex;
  position: relative;
  bottom: 1rem;
}

.menu a {
  text-decoration: none;
  color: black;
  font-family: "benjamine-detail";
  font-size: 18px;
}

.menu > * {
  font-family: "benjamine-detail";
}

.menu_drop {
  position: relative;
  cursor: pointer;
  font-size: 18px;
}

.menu_drop > ul {
  display: none;
  position: absolute;
  left: -2rem;
  padding-top: 0.5rem;
}

.menu_drop > ul > li {
  list-style-type: none;
  width: 200px;
}

.menu_drop:hover > ul {
  display: block;
}

.mobile-menu {
  display: none;
}

@media (max-width: 500px) {
  .mobile-menu {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 30px;
    gap: 6px;
    cursor: pointer;
  }

  .mobile-menu hr {
    width: 25px;
    height: 1.5px;
    background-color: black;
    border: 0px;
  }

  .menu {
    display: none;
  }

  header {
    padding: 1rem;
  }

  header .logo {
    font-size: 20px;
  }

  .modal {
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(250, 250, 250, 0.9);
    padding: 20% 0;
    justify-content: center;
    align-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .modal a {
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  .modal div {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }
}
