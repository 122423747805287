.footer {
  padding: 15vw 5vw;
}

.footer-mobile {
  display: none;
  padding: 10vw 5vw;
}

.image-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  .footer__image {
    position: unset !important;
    width: 100% !important;
  }

  .image-wrap {
    width: 50%;
  }

  .image-wrap a {
    padding: 5px;
  }

  .footer-mobile {
    display: flex;
    flex-wrap: wrap;
  }

  .footer {
    display: none;
  }
}
