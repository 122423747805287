@font-face {
  font-family: "benjamine";
  src: url("../public/font/web-font.ttf");
}

@font-face {
  font-family: "benjamine-detail";
  src: url("../public/font/web-detail-font.ttf");
}

@font-face {
  font-family: "benjamine-logo";
  src: url("../public/font/logo-font.ttf");
}

* {
  padding: 0;
  margin: 0;
  font-family: "benjamine", Arial, sans-serif;
  box-sizing: border-box;
}

.content {
  padding: 0 2vw;
  padding-top: 7rem;
}

[src=""] {
  display: none;
}
