.contact {
  padding: 10vw;
  display: flex;
  flex-wrap: wrap;
}

.contact__text {
  width: 30%;
}

.contact__text-bold {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: futura;
}

.contact__form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
}

.contact__title {
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: futura;
}

.contact__name {
  width: 49%;
  height: 2.5rem;
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 10px;
}

.contact__label {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 20px;
  color: grey;
}

.contact__name-bottom {
  width: 49%;
  font-size: 0.7rem;
  margin: 5px 0;
  color: grey;
}

.contact__input-100 {
  width: 100%;
  height: 2.5rem;
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 10px;
}

.contact__input-100--text-area {
  height: 5rem;
}

.contact__submit {
  margin: 2rem 0;
  padding: 0.7rem 2rem;
  border: 2px solid black;
  background-color: white;
}

.contact__logo {
  margin: 1rem 0;
}

.contact__logo--small {
  width: 50px;
  margin-right: 1rem;
}

@media (max-width: 500px) {
  .contact__form {
    width: 100%;
  }

  .contact__text {
    width: 100%;
  }

  .contact__submit {
    color: black;
    font-size: 1rem;
  }
}
