.main {
  width: 100%;
}

.left-banner a {
  display: flex;
}

.right-banner a {
  display: flex;
}

.middle-banner a {
  display: flex;
}

.banner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1vw 5vw;
}

.banner__left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: rgb(250, 250, 250);
}

.banner__text {
  font-size: 1.5rem;
  font-weight: 300;
}

.banner__right {
  width: 50%;
  height: 50vw;
}

.banner__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.left-banner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.5vw 5vw;
}

.left-banner__left {
  width: 55%;
}

.left-banner__left-image {
  width: 100%;
}

.left-banner__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  background-color: rgb(250, 250, 250);
}

.left-banner__right-image {
  width: 25vw;
}

.right-banner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.5vw 5vw;
}

.right-banner__right {
  width: 55%;
}

.right-banner__left-image {
  width: 25vw;
}

.right-banner__left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  background-color: rgb(250, 250, 250);
}

.right-banner__right-image {
  width: 100%;
}

.banner-option1 {
  display: flex;
  padding: 10vw 0;
  margin: 1.5vw 5vw;
  background-color: rgb(250, 250, 250);
}

.banner-option1__image {
  height: 35vw;
  padding-right: 1vw;
}

.middle-banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 1.5vw 5vw;
}

.middle-banner__left {
  width: 49.8%;
}

.middle-banner__left-image {
  width: 100%;
  object-fit: cover;
}

.middle-banner__right {
  width: 49.8%;
}

.middle-banner__right-image {
  width: 100%;
  object-fit: cover;
}

.full-banner {
  padding: 1.5vw 5vw;
}

.full-banner__image {
  width: 100%;
}

.detail-page__text a {
  cursor: unset;
}

.twice-banner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5vw 0vw;
  justify-content: space-around;
  background-color: rgb(250, 250, 250);
}

.twice-banner__left {
  display: flex;
  width: 35%;
  justify-content: center;
  align-items: center;
}

.twice-banner__left-image {
  width: 100%;
}

.twice-banner__right {
  display: flex;
  width: 35%;
  justify-content: center;
  align-items: center;
}

.twice-banner__right-image {
  width: 100%;
}

.pop-up {
  height: 80vh
}

@media (max-width: 500px) {
  .pop-up {
    height: 70vh;
  }

  .banner__text {
    font-size: 1rem !important;
  }

  .banner__left {
    width: 100%;
    height: 300px;
  }

  .banner__right {
    width: 100%;
  }

  .detail-page__text span {
    font-size: 20px;
  }
}
