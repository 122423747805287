.about {
  padding: 5vw 10vw;
  display: flex;
  flex-wrap: wrap;
}

.about__img-wrap {
  width: 30%;
}

.about__img {
  width: 100%;
  aspect-ratio: 1/1.5;
  object-fit: cover;
}

.about__text-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  align-content: center;
}

.about__text-top {
  font-size: 2.5rem;
  padding-left: 5vw;
  font-family: "benjamine-detail";
}

.about__text-bottom {
  font-size: 1rem;
  margin-top: 3rem;
  line-height: 30px;
  font-weight: 100;
}

@media (max-width: 500px) {
  .about__text-wrap {
    width: 100%;
    justify-content: center;
  }

  .about__img-wrap {
    width: 100%;
    margin-top: 50px;
  }

  .about__text-top {
    font-size: 1.5rem;
    text-align: center;
    padding: 0;
  }

  .about__text-bottom {
    font-size: 0.9rem;
    line-height: normal;
  }
}
